import React from 'react';

function Covid(props) {
  
  return (
    <div className="covid">
      <div className="wrapper">
        <div className="header">
          <h2>Covid-19</h2>
          <p>Useful resources you should know about:</p>

        </div>
      </div>
    </div>
  )
}

export default Covid;